import $ from 'jquery';

const $window = $(window);
const $bodyHtml = $('body,html');
const $html = $('html');
const $body = $('body');
const breakPoint1 = 1180;

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
  scrollPosi = $(window).scrollTop();
  $body.css({
    position: 'fixed',
    width: '100%',
    'z-index': '1',
    top: -scrollPosi,
    'overflow-y': 'scroll',
  });
}

function bodyFixReset() {
  $body.css({
    position: 'static',
    width: 'auto',
    top: '0',
  });
  $body.scrollTop(scrollPosi);
}

function bodyFixReset1() {
  $body.removeAttr('style');
  $bodyHtml.scrollTop(scrollPosi);
}

/* ======================================
Js header LP
====================================== */
$('.js-menu').on('click', function () {
  let menu = $('#menu');

  if (menu.hasClass('is-active')) {
    $(this).removeClass('is-active');
    $(this).siblings().removeClass('is-active');
    menu.removeClass('is-active');
    if ($('.is-overlay').length && $('.is-overlay').hasClass('is-active')) {
      $('.is-overlay').removeClass('is-active');
    }
    if ($window.outerWidth() < 1181) {
      bodyFixReset1();
    }

  } else {
    $(this).addClass('is-active');
    $(this).siblings().addClass('is-active');
    menu.addClass('is-active');
    if ($('.is-overlay').length) {
      $('.is-overlay').addClass('is-active');
    }
    if ($window.outerWidth() < 768) {
      bodyFix();
    }
  }
});
$('.js-closemenu').on('click', function () {
  $('.js-menu').trigger('click');
});

// $('.js-closemenu, .is-overlay').on('click', function () {
//   $('.js-menu').trigger('click');
// });

// $('.c-header__navinner').on('click', function (e) {
//   let menu = $('#menu');
//   if ($(window).outerWidth() < 768) {
//     if (e.target == this) {
//       $(this).removeClass('is-active');
//       $(this).siblings().removeClass('is-active');
//       $('.js-menu').siblings().removeClass('is-active');
//       $('.js-menu').removeClass('is-active');
//       menu.removeClass('is-active');
//       if ($('.is-overlay').length && $('.is-overlay').hasClass('is-active')) {
//         $('.is-overlay').removeClass('is-active');
//       }
//       bodyFixReset1();
//     }
//   }
// });
$('.is-submenu1').on("mouseenter", function () {
  if ($(window).outerWidth() > 1180) {
    $(this).find('.c-menu__submenu1').fadeIn(300);
  }
}).on("mouseleave", function () {
  if ($(window).outerWidth() > 1180) {
    $(this).find('.c-menu__submenu1').fadeOut(300);
  }
});

$('.js-toggle').on('click', function (e) {
  e.preventDefault();
  if ($(this).siblings().hasClass('is-active')) {
    $(this).removeClass('is-active');
    $(this).siblings().removeClass('is-active').slideDown();
  } else {
    $(this).addClass('is-active');
    $(this).siblings().addClass('is-active').slideUp();
  }
});


/* ======================================
animation scroll header
====================================== */
var navLinks = $(".c-header-lp__navItem a");
var sections = $($(".js-section-current").get().reverse());
$(window).on('scroll', function () {
  let scrollPos = $(window).scrollTop();
  if ($body.hasClass('page-top')) {
    let heightMV = $('.js-mv').outerHeight() - 50;
    if (scrollPos > 400 && $window.outerWidth() > 1180) {
      $('.js-header').addClass('is-remove');
    } else {
      $('.js-header').removeClass('is-remove');
    }
    if (scrollPos > heightMV && $window.outerWidth() > 1180) {
      $('.js-header').addClass('is-fixed');
    } else {
      $('.js-header').removeClass('is-fixed');
    }
  }

  //$('.c-header-lp').css("left", - $(window).scrollLeft());

  var currentScroll = $(this).scrollTop() + $('header').height();
  var currentSection = sections.filter(function () {
    return $(this).offset().top - 1 <= currentScroll;
  })[0];

  var id = currentSection ? currentSection.id : "";
  navLinks.removeClass("is-active");
  navLinks.filter("[href='#" + id + "']").addClass("is-active");

  // if ($(window).outerWidth() > 767) {
  //   $('.c-header.is-fixed').css("left", - $(window).scrollLeft());
  // } else {
  //   $('.c-header').css("left", 0);
  // }

});




//===================================================
// format
//===================================================
let navheader = $('.c-header__navinner'),
  submenu = $('.c-menu__submenu1');
$window.on('resize load', function () {
  if ($window.outerWidth() > breakPoint1) {
    $body.removeAttr('style');
    $('.js-menu, .is-overlay').removeClass('is-active');
    $('.js-header').find('.is-active').removeClass('is-active');
  }


  setTimeout(function () {
    return $window.outerWidth() < 1181 && navheader.css('transition', '.3s ease-in-out');
  }, 100);
  $window.outerWidth() > 1180 && navheader.removeAttr('style');
});
