import $ from 'jquery';

$(window).on('load', () => {
  const eleMatHeight = $('.js-matheight');

  if (eleMatHeight.length) {
    function handleMatHeight() {
      eleMatHeight.each(function () {
        let max = 0;
        const ele = $(this).find('.js-matheight-ele');

        ele.each(function () {
          if ($(this).innerHeight() > max) max = $(this).innerHeight();
        });

        ele.css('height', max);
      });
    }

    if ($(window).outerWidth() > 767) {
      handleMatHeight();
    }

    $(window).on('resize', () => {
      if ($(window).outerWidth() > 767) {
        handleMatHeight();
      } else {
        $('.js-matheight-ele').removeAttr('style');
      }
    });
  }
});
