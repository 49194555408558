import $ from 'jquery';
import Header from '../Components/_Header.js';
import Anchor from '../Components/_Anchor.js';
import Swiper from '../Components/_Slider.js';
import Form from '../Components/_Form.js';
import '../Components/_Imagemap.js';
import '../Components/_MatchHeight.js';
import '../Components/_Other.js';

$(window).on('load scroll resize orientationchange', function () {
  let htotop = $('.js-totop').innerHeight();
  let heightFooter;
  if ($('.c-copyright').length) {
    heightFooter = $('.c-copyright').offset().top + htotop / 2;
  }
  let wH = $(window).height();
  let scpos = $(window).scrollTop() + wH;
  let spAdjust;
  let apperBorder = wH + 100;
  if (wH > 768) {
    spAdjust = 0;
  } else {
    spAdjust = -50;
  }
  if (heightFooter + spAdjust < wH) {
    $('.js-totop').removeClass('is-init');
    $('.js-totop').addClass('is-stop');
  } else {
    if (scpos >= apperBorder) {
      $('.js-totop').removeClass('is-init');
      if (scpos >= heightFooter + spAdjust) {
        $('.js-totop').addClass('is-stop');
      } else {
        $('.js-totop').removeClass('is-stop');
      }
    } else {
      $('.js-totop').removeClass('is-stop');
      $('.js-totop').addClass('is-init');
    }
  }
});

$('.js-totop').on('click', function (e) {
  e.preventDefault();
  $('html, body').animate({ scrollTop: 0 }, 800);
});
