import $ from 'jquery';

const $window = $(window);
const $bodyHtml = $('body,html');
const $body = $('body');

/* ======================================
body fix
====================================== */
let scrollPosi;
$('a[href*=\\#]:not([href=\\#])').click(function () {
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
    location.hostname == this.hostname
  ) {
    var $target = $(this.hash);
    $target =
      ($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
    var offsetTop = $('header').outerHeight();

    var pathArray = window.location.pathname;
    if (pathArray === '/') {
      if ($window.outerWidth() < 768) {
        offsetTop = $('header').outerHeight();
      } else {
        offsetTop = 0;
      }
    }

    $('.js-menu').removeClass('is-active');
    $('.js-menu').siblings().removeClass('is-active');
    $('#menu').removeClass('is-active');

    scrollPosi = parseInt($('body').css('top'));

    if ($window.outerWidth() < 768) {
      if (scrollPosi) {
        $body.removeAttr('style');
        $bodyHtml.scrollTop(-1 * scrollPosi);
      }
    }

    if ($target.length) {
      var targetOffset = $target.offset().top - offsetTop;
      $('html,body').animate({ scrollTop: targetOffset }, 1000);
      return false;
    }
  }
});
var target = window.location.hash,
  target = target.replace('#', '');
window.location.hash = '';
$(window).on('load', function () {
  if (target) {
    $('html, body').animate(
      {
        scrollTop:
          $('#' + target).offset().top - $('.c-header-lp').outerHeight(),
      },
      0,
      'swing',
      function () {},
    );
  }
});
