import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';


const slider1 = new Swiper('.lp-slide', {
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  speed: 500,
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionTotal: function (number) {
      if(number < 10){
        return '0' + number;
      }else{
        return number
      }
    },
    formatFractionCurrent: function (number) {
      if(number < 10){
        return '0' + number;
      }else{
        return number
      }
    }
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  on: {
    init: function () {
      $(".swiper-progress-bar").removeClass("animate");
      $(".swiper-progress-bar").removeClass("active");
      $(".swiper-progress-bar").eq(0).addClass("animate");
      $(".swiper-progress-bar").eq(0).addClass("active");
    },
    slideChangeTransitionStart: function () {
      $(".swiper-progress-bar").removeClass("animate");
      $(".swiper-progress-bar").removeClass("active");
      $(".swiper-progress-bar").eq(0).addClass("active");
    },
    slideChangeTransitionEnd: function () {
      $(".swiper-progress-bar").eq(0).addClass("animate");
    }
  },
});


$(window).on('load', function () {
  $('.js-mvtop').addClass('is-active');
  setTimeout(() => {
    $('.js-mvtop').addClass('is-active1');
  }, 4000);

  setTimeout(() => {
    $('.js-mvtop').addClass('is-active2');

    const slider2 = new Swiper('.js-mvtop__slide', {
      effect: "fade",
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      speed: 2000,
      loop: true,
    });
  }, 6000);
});