import $ from 'jquery';

$(window).on('scroll load', function(){
  if($(window).scrollTop() > 300){
    $('#scrolltop').addClass('is-active');
  }else{
    $('#scrolltop').removeClass('is-active');
  }
});

$('#scrolltop').on('click', function (){
  $('html,body').animate({ scrollTop: 0 }, 1000);
});


$(window).on( 'load', function(){

  if( $(document).find('.mw_wp_form .c-form1__right').hasClass('is-error') || $(document).find('.mw_wp_form').hasClass('mw_wp_form_complete') || $(document).find('.mw_wp_form').hasClass('mw_wp_form_confirm')){
    $('.mw_wp_form').siblings().find('.c-btn01').hide();
  }
});

